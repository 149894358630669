<template>
    <div>
        <!-- <card-header :header="headers"></card-header> -->
        <section class="px-0 flex flex-column">
            <div class="w-full">
                <ul class="nav-dash-tab w-full">
                    <li class="text-center font-bold uppercase" v-for="(tab, i) in tabs" :key="i"
                        v-on:click="toggleTabs(i + 1)"
                        v-bind:class="{ 'nav-dash-tab-item': openTab !== (i + 1), 'nav-dash-tab-item-active': openTab === (i + 1) }">
                        {{ tab.name }}
                    </li>
                </ul>
            </div>
            <div class="flex flex-col bg-white w-full mt-6 rounded-lg">
                <div class="px-4 py-5 flex-auto">
                    <div class="tab-content tab-space">
                        <div v-bind:class="{ 'hidden': openTab !== 1, 'block': openTab === 1 }">
                            <service-data-table :isProduct="false"></service-data-table>
                        </div>
                        <div v-bind:class="{ 'hidden': openTab !== 2, 'block': openTab === 2 }">
                            <service-data-table :isProduct="true"></service-data-table>
                        </div>
                        <div v-bind:class="{ 'hidden': openTab !== 3, 'block': openTab === 3 }">
                            <new-services></new-services>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import CardHeader from '@/views/dashboard/components/CardHeader.vue'
import NewServices from '@/views/dashboard/services/NewServices.vue'
import ServiceDataTable from '@/views/dashboard/services/ServiceDataTable.vue'

export default {
    components: {
        CardHeader,
        ServiceDataTable,
        NewServices
    },
    data() {
        return {
            headers: {
                titlepage: 'SERVICES & PRODUCT',
                icon: null,
            },
            currentTab: null,
            oldTab: null,
            loading_rfp_list: true,
            serviceDatas: [],

            openTab: 1,
            tabs: [
                { name: "Services", link: { name: 'company.service.list' } },
                { name: "Products", link: { name: 'company.service.list' } },
                { name: "+ Add", link: { name: 'company.service.new' } },
            ],
        }
    },

    methods: {
        toggleTabs: function (tabNumber) {
            this.openTab = tabNumber
        }
    },
    beforeUpdate() {

    },
    beforeCreate() {
        this.$router.push({ name: 'company.service.list' });
    },

    setup(props) {
        const tabdata = ref()

        return {
            tabdata,
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/styles/tabs.scss';


.avatar-center {
    top: -50%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
}
</style>
